import { GlobalStyles } from "@mui/material";
import React from "react";
import Layout from "./src/components/layout";

export default ({ element, props }) => {
  return (
    <Layout {...props}>
      <GlobalStyles
        styles={(theme) => ({
          // '.html p': {
          //   margin: '0',
          //   marginBottom: '10px'
          // },
          'html': {
            // overflowX: 'hidden',
            backgroundColor: theme.palette.primary.light,
          },
          'body': {
            // overflowX: 'hidden',
            // color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.light,
          }
        })}
      />
      {element}
    </Layout>
  )
}
