import { KeyboardArrowDown } from '@mui/icons-material';
import { Divider, LinkTypeMap, Menu, Link as MuiLink, Stack, StackProps, Typography, TypographyProps, useTheme } from '@mui/material';
import React, { ElementType, ReactNode, useState } from 'react';
import { Link, LinkLabelAndDescription, LinkPropsBase, onLinkClick } from '../link';

export type LinkGroupProps = LinkPropsBase & {
    links: LinkPropsBase[] | null | undefined
    withDropdown?: boolean
    alignLeft?: boolean
    headerComponent?: string
    forceLink?: boolean
    LinkProps?: LinkTypeMap['props']
    LinkGroupItemsTypographyProps?: TypographyProps
    LinkGroupHeaderTypographyProps?: TypographyProps
    LinkGroupHeaderLabelToIconMapping?: Record<string, ReactNode>
    LinkGroupHeaderLinkProps?: LinkTypeMap['props']
    LinkGroupItemsStackProps?: StackProps
    onClick?: () => void
}

export type LinkGroupItemsProps = {
    links: LinkPropsBase[] | null | undefined,
    alignLeft?: boolean,
    relativeLinkComponent?: ElementType,
    forceLink?: boolean,
    LinkProps?: LinkTypeMap['props'],
    LinkGroupItemsTypographyProps?: TypographyProps,
    LinkGroupItemsStackProps?: StackProps
    onClick?: () => void
}

function LinkGroupItems({
    links,
    alignLeft,
    relativeLinkComponent,
    forceLink,
    LinkProps,
    LinkGroupItemsTypographyProps,
    LinkGroupItemsStackProps,
    onClick
}: LinkGroupItemsProps) {
    if (!links || links.length === 0) return <></>
    return <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={{ xs: 0, sm: 0, md: 2 }}
        {...LinkGroupItemsStackProps}
    >
        {links.map((link, i) => {
            const linkElement = <Link
                relativeLinkComponent={relativeLinkComponent}
                key={i}
                forceLink={forceLink}
                label={link.label}
                url={link.url}
                LinkProps={LinkProps}
                onClick={onClick}
                sx={!alignLeft ? {
                    width: { xs: "100%", sm: "100%", md: "inherit" }, // SEO
                    minHeight: { xs: "48px", sm: "48px", md: "inherit" }, // SEO
                    ...LinkProps?.sx
                } : { ml: 5, ...LinkProps?.sx }}
            />;
            return (
                LinkGroupItemsTypographyProps ? <Typography {...LinkGroupItemsTypographyProps} key={i}>{linkElement}</Typography> : linkElement
            )
        })}
    </Stack>
}

export function LinkGroup({
    label,
    description,
    url,
    links,
    withDropdown,
    alignLeft,
    relativeLinkComponent,
    headerComponent,
    forceLink,
    LinkProps,
    LinkGroupItemsTypographyProps,
    LinkGroupHeaderTypographyProps,
    LinkGroupHeaderLabelToIconMapping,
    LinkGroupHeaderLinkProps,
    LinkGroupItemsStackProps,
    onClick
}: LinkGroupProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseAndClick = (urlLink: string | undefined | null) => {
        onLinkClick(urlLink, undefined, onClick)
        setAnchorEl(null);
    };

    const hasSubLinks = links && links.length > 0
    const showHeaderButton = (url || (hasSubLinks && withDropdown));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, urlLink: string | undefined | null) => {
        setAnchorEl(event.currentTarget);
        onLinkClick(urlLink, event, onClick)
    };

    return (
        <Stack gap={{ xs: 0, sm: 0, md: 2 }}>
            {label && <Typography variant={headerComponent as any} {...LinkGroupHeaderTypographyProps as any} component={"div"} sx={!headerComponent ? { fontSize: "1rem", fontWeight: 700, ...LinkGroupHeaderTypographyProps?.sx } : LinkGroupHeaderTypographyProps?.sx}>
                {showHeaderButton && <Link endIcon={withDropdown && links && links.length > 0 && <KeyboardArrowDown htmlColor={theme.palette.text.disabled} />} labelToIconMapping={LinkGroupHeaderLabelToIconMapping} LinkProps={{ ...LinkGroupHeaderLinkProps, ...LinkProps, underline: 'none' }} forceLink={!withDropdown || forceLink} sx={{ textTransform: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', ...LinkGroupHeaderLinkProps?.sx }} relativeLinkComponent={!hasSubLinks ? relativeLinkComponent : undefined} url={url} label={label} description={description} onClick={(e) => {
                    if (withDropdown) handleClick(e, url)
                    else onLinkClick(url, e, onClick)
                }} />}
                {!showHeaderButton ? label : undefined}
            </Typography>}

            {links && links.length > 0 && (
                withDropdown ? (
                    <Menu
                        id={`menu-${label}`}
                        anchorEl={anchorEl}
                        open={open}
                        disableScrollLock
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': `menu-btn-${label}`,
                        }}
                    >
                        <Stack gap={2}
                            divider={<Divider />}
                            sx={{ pl: 2, pr: 2, pb: 1, pt: 1 }}>
                            {links.map((link, i) => {
                                const isAbsoluteUrl = link.url && link.url.startsWith("http")

                                return (
                                    relativeLinkComponent && !isAbsoluteUrl ?
                                        (
                                            <MuiLink component={relativeLinkComponent} underline='none' href={link.url || undefined} to={link.url || undefined} key={i}>
                                                {/* <MenuItem onClick={handleClose}>{link.label}</MenuItem> */}
                                                <LinkLabelAndDescription TypographyProps={LinkGroupItemsTypographyProps} label={link.label} description={link.description} onClick={() => handleCloseAndClick(link.url)} />
                                            </MuiLink>
                                        ) : (
                                            <MuiLink underline='none' href={link.url || undefined} target={isAbsoluteUrl ? '_blank' : undefined} key={i}>
                                                {/* <MenuItem onClick={handleClose}>{link.label}</MenuItem> */}
                                                <LinkLabelAndDescription TypographyProps={LinkGroupItemsTypographyProps} label={link.label} description={link.description} onClick={() => handleCloseAndClick(link.url)} />
                                            </MuiLink>
                                        )

                                )

                            })}
                        </Stack>
                    </Menu>
                ) : (
                    <LinkGroupItems onClick={onClick} LinkGroupItemsStackProps={LinkGroupItemsStackProps} LinkGroupItemsTypographyProps={LinkGroupItemsTypographyProps} forceLink={forceLink} LinkProps={LinkProps} links={links} alignLeft={alignLeft} relativeLinkComponent={relativeLinkComponent} />
                )
            )}
        </Stack>
    )
}
