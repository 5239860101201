import { ChevronRight } from "@mui/icons-material";
import { Button, ButtonProps, LinkTypeMap, Link as MuiLink, Stack, Typography, TypographyProps, useTheme } from "@mui/material";
import React, { ElementType, ReactNode, useMemo } from "react";

export type LinkPropsBase = {
    url: string | undefined | null
    label?: string | undefined | null
    mobileLabel?: string | undefined | null
    description?: string | undefined | null
    relativeLinkComponent?: ElementType;
    forceLink?: boolean;
    LinkProps?: LinkTypeMap['props']
}

export type LinkProps = Omit<ButtonProps, 'href'> & LinkPropsBase & {
    labelToIconMapping?: Record<string, ReactNode>
}

export function LinkLabelAndDescription({ label, description, onClick, labelToIconMapping, TypographyProps }: { label: string | undefined | null, description: string | undefined | null, onClick?: () => void, labelToIconMapping?: Record<string, ReactNode>, TypographyProps?: TypographyProps }) {
    const theme = useTheme();

    // const labelText = useMemo

    const labelEl = useMemo(() => {
        return label && labelToIconMapping && labelToIconMapping[label] ? labelToIconMapping[label] : label
    }, [label, labelToIconMapping])

    if (!description) {
        if (!onClick) {
            return labelEl;
        }
        else {
            return <Stack gap={2} direction="row" onClick={onClick} alignItems="center" justifyContent="space-between">
                <Typography variant="h4" sx={{ fontSize: theme.typography.h5.fontSize }} gutterBottom={false} {...TypographyProps}>{labelEl}</Typography>
                <ChevronRight />
            </Stack>
        }
    }

    return (<Stack gap={2} direction="row" onClick={onClick} alignItems="center" justifyContent="space-between">
        <Stack gap={0.5}>
            <Typography variant="h4" sx={{ fontSize: theme.typography.h5.fontSize }} gutterBottom={false} {...TypographyProps}>{labelEl}</Typography>
            <Typography variant="body1">{description}</Typography>
        </Stack>
        <ChevronRight />
    </Stack>)
}

export const onLinkClick = (url: string | undefined | null, e: React.MouseEvent | undefined, onClick: ((e: React.MouseEvent) => void) | undefined | null) => {
    if (onClick) {
        onClick(e as any)
    }
}

export function Link({ url, label, description, relativeLinkComponent, forceLink, LinkProps, children, labelToIconMapping, ...other }: LinkProps) {
    const isAbsoluteUrl = url && url.startsWith("http")

    // console.log('heree relative', label, relativeLinkComponent)

    const onClick = (e: React.MouseEvent) => {
        onLinkClick(url || '', e, other.onClick as any)
    }

    if (forceLink) {
        return relativeLinkComponent && !isAbsoluteUrl ?
            <MuiLink underline='none' component={relativeLinkComponent} color="inherit" href={url || undefined} to={url || undefined} {...other as any} onClick={onClick} sx={{ fontSize: 'inherit', ...other.sx, ...LinkProps?.sx }} {...LinkProps}>
                {children ? children : <LinkLabelAndDescription labelToIconMapping={labelToIconMapping} label={label} description={description} />}
            </MuiLink>
            :
            <MuiLink underline='none' color="inherit" href={url || undefined} {...isAbsoluteUrl ? { target: '_blank' } : undefined} to={url || undefined} {...other as any} onClick={onClick} sx={{ fontSize: 'inherit', ...other.sx, ...LinkProps?.sx }} {...LinkProps}>
                {children ? children : <LinkLabelAndDescription labelToIconMapping={labelToIconMapping} label={label} description={description} />}
            </MuiLink>
    }
    else {

        return relativeLinkComponent && !isAbsoluteUrl ?
            <Button component={relativeLinkComponent} variant="text" color="inherit" href={url || undefined} to={url || undefined} {...other} onClick={onClick} sx={{ fontSize: 'inherit', ...other.sx }} {...LinkProps as any}>
                <LinkLabelAndDescription labelToIconMapping={labelToIconMapping} label={label} description={description} />
            </Button>
            :
            <Button variant="text" color="inherit" href={url || undefined} {...isAbsoluteUrl ? { target: '_blank' } : undefined} {...other} onClick={onClick} sx={{ fontSize: 'inherit', ...other.sx }} {...LinkProps as any}>
                {children ? children : <LinkLabelAndDescription labelToIconMapping={labelToIconMapping} label={label} description={description} />}
            </Button>
    }
}