import '@fontsource/noto-sans/300.css';
import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/700.css';

import wrapPageElements from './wrap-page-element';
import wrapWithProviders from './wrap-with-providers';

export const wrapPageElement = wrapPageElements

export const wrapRootElement = wrapWithProviders
