import React, { useMemo } from 'react';

import styled from '@emotion/styled';
import { Box, Button, Container, Divider, Link, Stack, Theme, Typography, useTheme } from '@mui/material';


import { LinkGroupProps, LinkList } from '@ajc/lib-mui';
import { notPrintable } from '@ajc/lib-styles';
import { Circle, Email, Facebook, Instagram, LinkedIn, Phone, YouTube } from '@mui/icons-material';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';

const useStyles = (theme: Theme) => ({
    background: {
        background: theme.palette.primary.light,
        pt: { xs: 4, sm: 4, md: 6 },
        pb: { xs: 8, sm: 8, md: 6 },
    },
})

function SocialLink({ platform, url }: { platform: 'Facebook' | 'LinkedIn' | 'YouTube' | 'Instagram', url: string }) {
    let icon: any;
    switch (platform) {
        case 'Facebook':
            icon = <Facebook fontSize='small' />;
            break;
        case 'LinkedIn':
            icon = <LinkedIn fontSize='small' />;
            break;
        case 'YouTube':
            icon = <YouTube fontSize='small' />;
            break;
        case 'Instagram':
            icon = <Instagram fontSize='small' />;
            break;
        default:
            icon = <Circle fontSize='small' />;
    }

    return (
        <Stack direction={"row"} gap={1} sx={{ color: 'inherit' }}>
            {icon}
            <Link href={url} target="_blank" rel="noopener noreferrer" variant='body2' sx={{ color: 'inherit', textDecoration: 'underline' }}>{platform}</Link>
        </Stack>
    )
}

export default function Footer() {
    const theme = useTheme();
    const styles = useStyles(theme)

    const footerDataQuery: Queries.FooterComponentQuery = useStaticQuery(graphql`
    query FooterComponent {
      strapiWebsiteInfo {
        contactEmail: ContactEmail
        contactPhoneText: ContactPhoneText
        contactPhoneE164: ContactPhoneE164
        companyName: CompanyName
        contactPhoneLong: ContactPhoneLong
        companyAddress: CompanyAddress
        postalAddress: PostalAddress
        socials: Socials {
          platform: Platform
          url: URL
        }
      }
      strapiFooter {
        logo: Logo {          
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, width: 230, quality: 100)
            }
          }
        }
        disclaimerTitle: DisclaimerTitle
        disclaimerContent: DisclaimerContent
        links: Links {
          url: URL
          label: Label
          links: Links {
            label: Label
            url: URL
          }
        }
      }
    }
  `)

    const NotPrintable = useMemo(() => styled('div')(notPrintable), []);

    const footerProps = useMemo(() => {
        return { ...footerDataQuery.strapiFooter }
    }, [])

    const websiteInfoProps = useMemo(() => {
        return { ...footerDataQuery.strapiWebsiteInfo }
    }, [])

    const logo = footerProps?.logo?.localFile?.childImageSharp?.gatsbyImageData;

    const divider = <Divider sx={{ borderColor: '#445239', borderWidth: '1px' }} />

    const thisYear = useMemo(() => new Date().getFullYear(), [])

    return (
        <NotPrintable>
            <Box component="footer" sx={styles.background}>
                <Container>
                    <Stack gap={2}>
                        {websiteInfoProps.companyName && (
                            <GatsbyLink to="/" style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                                <Box sx={{ display: "none" }}>Home</Box>
                                {/* {logo && <GatsbyImage image={logo} alt="logo" loading='eager' />} */}
                                <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: '500' }} >{websiteInfoProps.companyName}</Typography>
                            </GatsbyLink>
                        )}

                        <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 4, sm: 4, md: 10, lg: 14 }} justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }}>
                            <Stack gap={1} direction={'column'} sx={{ maxWidth: '210px' }}>
                                <Typography variant="h6">Contact us</Typography>
                                <Stack gap={3}>
                                    <Typography variant="body2">Get in touch for enquiries, feedback, complaints and compliments.</Typography>

                                    <Stack gap={1}>
                                        {(websiteInfoProps.contactPhoneE164 || websiteInfoProps.contactPhoneText) && (
                                            <Stack direction='row' gap={1}>
                                                <Phone fontSize='small' />
                                                <Typography variant="body2" component="div">Phone: <Link sx={{ color: theme.palette.text.primary }} underline='none' href={websiteInfoProps.contactPhoneE164 ? 'tel:' + websiteInfoProps.contactPhoneE164 : undefined}>{websiteInfoProps.contactPhoneText || websiteInfoProps.contactPhoneE164}</Link></Typography>
                                            </Stack>
                                        )}

                                        {websiteInfoProps.contactEmail && (
                                            <Stack direction='row' gap={1}>
                                                <Email fontSize='small' />
                                                <Typography variant="body2" component="div">Email: <Link sx={{ color: theme.palette.text.primary }} underline='none' href={'mailto:' + websiteInfoProps.contactEmail}>{websiteInfoProps.contactEmail}</Link></Typography>
                                            </Stack>
                                        )}
                                    </Stack>

                                    <Box>
                                        <Button LinkComponent={GatsbyLink} variant='contained' color='secondary' size="small" href="/contact" {...{ to: '/contact' }}>Contact us</Button>
                                    </Box>
                                </Stack>
                            </Stack>

                            <Stack divider={divider} direction={{ xs: "column", md: "row" }} gap={{ xs: 3, sm: 3, md: 4 }} justifyContent={{ xs: 'center', sm: 'center', md: 'space-between' }}>
                                {footerProps?.links && (
                                    <LinkList
                                        headerComponent="h6"
                                        forceLink
                                        links={footerProps?.links as LinkGroupProps[]}
                                        StackProps={{ direction: { xs: "column", md: "row" }, justifyContent: 'center', divider: divider, gap: { xs: 3, sm: 3, md: 4 } }}
                                        relativeLinkComponent={GatsbyLink}
                                        LinkProps={{ underline: 'always' }}
                                        LinkGroupItemsTypographyProps={{ variant: 'body2', color: theme.palette.text.primary }}
                                    />
                                )}

                                {websiteInfoProps.socials && websiteInfoProps.socials.length > 0 && (
                                    <Stack gap={2}>
                                        <Typography variant="h6">Follow us</Typography>
                                        {
                                            websiteInfoProps.socials.map((social, i) => {
                                                if (!social || !social.platform || !social.url) return <></>;

                                                return <SocialLink key={i} platform={social.platform as any} url={social.url} />
                                            })
                                        }
                                    </Stack>
                                )}

                                <Stack gap={2} sx={{ maxWidth: '220px' }}>
                                    <Typography variant="h6">{footerProps.disclaimerTitle || ''}</Typography>
                                    <Typography variant="body2">{footerProps.disclaimerContent}</Typography>
                                    <Stack gap={0.5}>
                                        <Typography variant="body2">&copy; {thisYear} {websiteInfoProps.companyName}.</Typography>
                                        <Typography variant="body2">All rights reserved.</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </NotPrintable>
    )
}
