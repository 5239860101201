import React from 'react';


import { Container } from '@mui/material';
import Footer from './footer';
import Header from './header';

interface LayoutProps {
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />

      <Container maxWidth='xl' sx={{ pt: 4, pb: 4 }}>
        {children}
      </Container>

      <Footer />
    </>
  )
}

export default Layout
