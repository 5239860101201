import { createTheme, Shadows } from '@mui/material/styles';

const defaultTheme = createTheme();
const themeShadows: Shadows = [...defaultTheme.shadows]
themeShadows["1"] = '2px 2px 5px rgba(0, 0, 0, 0.3)';

const theme = createTheme({
  shadows: themeShadows,
  palette: {
    primary: {
      light: '#6D8B57', // Lighter shade of green as the primary color
      main: '#789C5C', // Green as the primary color
      dark: '#4F6D32', // Darker shade of green for contrast
    },
    secondary: {
      light: '#D9894C', // Lighter shade of yellow as the secondary color
      main: '#4B2E11', // Dark brown as the secondary color,
      dark: '#8A5837', // Darker shade of brown for contrast
      contrastText: '#FFFFFF', // White text for contrast
    },
    background: {
      default: '#F4F4F4', // Light background color for the page
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1A1A1A', // Dark text color
      secondary: '#4B2E11', // Secondary text using brown
    },
    action: {
      active: '#F2994A', // Highlight or active color
      hover: '#F2C94C', // Assuming a hover color slightly lighter than active
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      color: '#4B2E11', // Brown color for headings
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 700,
      color: '#4B2E11',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: '#4B2E11',
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: '#1A1A1A',
    },
    body1: {
      fontSize: '1rem',
      color: '#1A1A1A',
    },
    body2: {
      fontSize: '0.875rem',
      color: '#1A1A1A', // Using secondary color for additional text styles
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 600,
      textTransform: 'none', // Keeping buttons title case as per the design
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 29px',
          color: '#FFFFFF',
          fontWeight: 'normal',
        },
        containedPrimary: {
          backgroundColor: '#4F6D32', // Matching the primary green color
          '&:hover': {
            backgroundColor: '#2b3c1b', // Slightly darker shade for hover
          },
          boxShadow: themeShadows["1"],
        },
        containedSecondary: {
          color: '#1A1A1A',
          backgroundColor: '#D9894C', // Matching the primary green color
          '&:hover': {
            backgroundColor: '#db9b6b', // Slightly darker shade for hover
          },
          boxShadow: themeShadows["1"],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: themeShadows["1"],
          overflow: 'hidden',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#789C5C', // Primary green color for AppBar
          color: '#FFFFFF',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#4B2E11', // Brown accent for chips
          color: '#FFFFFF',
          fontWeight: 600,
        },
      },
    },
  },
  shape: {
    borderRadius: 1.5,
  }
});

export const MaterialUiTheme = theme;