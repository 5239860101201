import React from 'react';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { MaterialUiTheme } from '@ajc/lib-mui';

export default ({ element }) => {
  return (
    <ThemeProvider theme={MaterialUiTheme}>
      <CssBaseline />

      {element}
    </ThemeProvider>
  )
}