import { LinkTypeMap, Stack, StackProps, TypographyProps } from '@mui/material';
import { ElementType, ReactNode } from 'react';
import { LinkGroup, LinkGroupProps } from '../linkGroup';

export type LinkListProps = {
    links: LinkGroupProps[] | null | undefined
    withDropdown?: boolean
    alignLeft?: boolean
    forceLink?: boolean
    StackProps?: StackProps
    LinkGroupItemsStackProps?: StackProps
    relativeLinkComponent?: ElementType;
    headerComponent?: string
    LinkProps?: LinkTypeMap['props']
    LinkGroupItemsTypographyProps?: TypographyProps
    LinkGroupHeaderTypographyProps?: TypographyProps
    LinkGroupHeaderLabelToIconMapping?: Record<string, ReactNode>
    LinkGroupHeaderLinkProps?: LinkTypeMap['props']
    onClick?: () => void
}

export function LinkList({
    links,
    withDropdown,
    StackProps,
    alignLeft,
    relativeLinkComponent,
    headerComponent,
    forceLink,
    LinkProps,
    LinkGroupItemsTypographyProps,
    LinkGroupHeaderLinkProps,
    LinkGroupHeaderTypographyProps,
    LinkGroupHeaderLabelToIconMapping,
    LinkGroupItemsStackProps,
    onClick
}: LinkListProps) {
    if (!links) return <></>

    return (
        <Stack gap={{ xs: 2, md: 4, lg: 6 }} {...StackProps}>
            {links.map((link, i) => {
                return (
                    <LinkGroup
                        key={i}
                        label={link.label}
                        url={link.url}
                        description={link.description}
                        links={link.links}
                        onClick={onClick}
                        withDropdown={withDropdown}
                        LinkGroupHeaderLabelToIconMapping={LinkGroupHeaderLabelToIconMapping}
                        LinkGroupItemsStackProps={LinkGroupItemsStackProps}
                        alignLeft={alignLeft}
                        forceLink={forceLink}
                        headerComponent={headerComponent}
                        LinkProps={LinkProps}
                        LinkGroupHeaderTypographyProps={LinkGroupHeaderTypographyProps}
                        LinkGroupItemsTypographyProps={LinkGroupItemsTypographyProps}
                        LinkGroupHeaderLinkProps={LinkGroupHeaderLinkProps}
                        relativeLinkComponent={relativeLinkComponent || link.relativeLinkComponent}
                    />
                )
            })}
        </Stack>
    )
}
