import { Link as GatsbyLink, GatsbyLinkProps, graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';

import { Link, LinkGroupProps, LinkList } from '@ajc/lib-mui';
import { notPrintable } from '@ajc/lib-styles';
import styled from '@emotion/styled';
import { Close, HomeOutlined, Menu, Phone } from '@mui/icons-material';
import {
  AppBar, Box,
  Button,
  Container, Divider, IconButton,
  Stack, Theme, Toolbar, Typography, useTheme
} from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

const desktopNavBarBottomBorderWidth = '6px'
const desktopNavBarBottomBorderColor = "#F5F5F5"

export const mobileLinkStyle = (muiTheme: Theme) => ({
  display: "block",
  color: "white",
  zIndex: 1,
  fontSize: muiTheme.typography.h3.fontSize,
  paddingTop: muiTheme.spacing(1),
  paddingBottom: muiTheme.spacing(1),
  paddingLeft: muiTheme.spacing(4),
  paddingRight: muiTheme.spacing(4),
  marginBottom: muiTheme.spacing(1),
})

export const mobileAppFloatingMenuStyle = (muiTheme: Theme) => ({
  color: "white",
  zIndex: 999,
  // fontSize: muiTheme.typography.h3.fontSize,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  pt: 10,
  pl: 1,
  pr: 1,
  position: "absolute",
  background: muiTheme.palette.primary.dark,
})

const desktopNavItemBorderStyles = (theme: Theme, color: string) => ({
  borderStyle: 'solid',
  borderColor: color,
  borderWidth: '0',
  borderBottomWidth: desktopNavBarBottomBorderWidth,
})
const navLinkActiveStyles = (theme: Theme) => ({
  color: theme.palette.primary.main,
  ...desktopNavItemBorderStyles(theme, desktopNavBarBottomBorderColor)
})

const navLinkStyles = (theme: Theme) => ({
  color: 'inherit',
  fontWeight: 'normal',
  fontSize: '1rem',
  pb: 1.5,
  height: '48px',
  ...desktopNavItemBorderStyles(theme, 'transparent')
})

export const desktopAppBarStyle = (theme: Theme) => ({
  background: desktopNavBarBottomBorderColor,
  color: theme.palette.text.primary,
  // pt: 2,
  display: {
    xs: "none",
    sm: "none",
    md: "inherit",
    lg: "inherit",
    xl: "inherit",
  },
  height: '48px',
  boxShadow: theme.shadows["1"],
  ...desktopNavItemBorderStyles(theme, '#5F2116')
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const desktopTopAppBarStyle = (theme: Theme) => ({
  background: theme.palette.secondary.dark,
  color: theme.palette.secondary.contrastText,
  pt: theme.spacing(0.5),
  pb: theme.spacing(0.5),
  pr: theme.spacing(0),
  display: {
    xs: "none",
    sm: "none",
    md: "inherit",
    lg: "inherit",
    xl: "inherit",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const desktopMiddleAppBarStyle = (theme: Theme) => ({
  background: 'white',
  color: theme.palette.secondary.contrastText,
  pt: theme.spacing(4),
  pb: theme.spacing(4),
  display: {
    xs: "none",
    sm: "none",
    md: "inherit",
    lg: "inherit",
    xl: "inherit",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const mobileAppBarStyle = () => ({
  // background: "transparent",
  // pt: 1,
  // pr: 1,
  // pl: 0,
  display: {
    xs: "inherit",
    sm: "inherit",
    md: "none",
    lg: "none",
    xl: "none",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const baseLogoHeight = 48
export const baseLogoWidth = 230

export default function Header() {
  const headerDataQuery: Queries.HeaderComponentQuery = useStaticQuery(graphql`
    query HeaderComponent {
      strapiWebsiteInfo {
        companyName: CompanyName
      }
      strapiHeader {
        logo: Logo {          
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, height: 80, quality: 100)
            }
          }
        }
        links: Links {
          url: URL
          label: Label
          links: Links {
            label: Label
            url: URL
          }
        }
      }
    }
  `)

  const NotPrintable = useMemo(() => styled('div')(notPrintable), []);

  const headerProps = useMemo(() => {
    return { ...headerDataQuery }
  }, [])

  return (
    <NotPrintable>
      <MobileHeader {...headerProps} />
      <DesktopHeader {...headerProps} />
    </NotPrintable>
  )
}
type HeaderComponentProps = Queries.HeaderComponentQuery

const DesktopHeader = ({ strapiHeader, strapiWebsiteInfo }: HeaderComponentProps) => {
  const theme = useTheme();
  const appBarStyle = desktopAppBarStyle(theme)
  const topAppBarStyle = desktopTopAppBarStyle(theme)
  const middleBarStyle = desktopMiddleAppBarStyle(theme)
  const navLinkActiveStyle = navLinkActiveStyles(theme)
  const logo = strapiHeader?.logo?.localFile?.childImageSharp?.gatsbyImageData;
  const navLinkStyle = navLinkStyles(theme)

  // const navLinkSxActive = useMemo(() => ({ color: '#022A50' }), []);

  const iconMapping = useMemo(() => {
    return { 'Home': <HomeOutlined color="primary" sx={{ fontWeight: 'normal', color: 'inherit' }} /> }
  }, [])

  return (
    <>
      <AppBar position="static" elevation={0} sx={{ ...topAppBarStyle }}>
        <Container maxWidth="xl">
          <Stack direction='row' justifyContent='flex-end' alignItems='center' sx={{ width: '100%', mr: 2 }}>
            <Stack direction={'row'} gap={1}>
              <Phone htmlColor={theme.palette.secondary.light} />
              <Link forceLink variant='text' label={"Contact information"} relativeLinkComponent={GatsbyLink} url="/contact" />
            </Stack>
          </Stack>
        </Container>
      </AppBar>
      <AppBar position="static" elevation={0} sx={{ ...middleBarStyle }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction={"row"}
                divider={<Divider orientation="vertical" sx={{ borderColor: theme.palette.secondary.dark, borderWidth: 1.2, mt: 4, mb: 4 }} flexItem />}
                justifyContent="flex-start"
                gap={6}
                alignItems="center"
              >
                <GatsbyLink to="/">
                  <Box sx={{ display: "none" }}>Home</Box>
                  {logo && <Box pt={1} pb={1}><GatsbyImage image={logo} alt="logo" loading='eager' /></Box>}
                </GatsbyLink>

                <Typography variant="h2" sx={{ textTransform: 'uppercase', color: "#022A50" }}>{strapiWebsiteInfo?.companyName}</Typography>
              </Stack>

              <Button color="primary" variant="contained">Quick exit</Button>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      <AppBar position="static" elevation={0} sx={{ ...appBarStyle }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ minHeight: '0px!important' }}>
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <nav>
                <Stack
                  direction={"column"}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {strapiHeader?.links && (
                    <LinkList
                      LinkGroupHeaderLabelToIconMapping={iconMapping}
                      links={strapiHeader?.links as LinkGroupProps[]}
                      LinkGroupItemsStackProps={{ gap: 1 }}
                      LinkGroupItemsTypographyProps={{ sx: { fontSize: '1rem', color: theme.palette.text.primary, pl: 0.5 } }}
                      LinkGroupHeaderLinkProps={{ sx: { ...navLinkStyle, paddingTop: '12px' } }}
                      LinkGroupHeaderTypographyProps={{ sx: navLinkStyle }}
                      withDropdown
                      StackProps={{ direction: "row" }}
                      // StackProps={{ direction: "row", gap: { md: 1, lg: 2 } }}
                      relativeLinkComponent={GatsbyLink}
                      LinkProps={{ activeStyle: navLinkActiveStyle } as GatsbyLinkProps<any> as any}
                    />
                  )}
                </Stack>
              </nav>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </>

  )
}

const MobileHeader = ({ strapiHeader }: HeaderComponentProps) => {
  const theme = useTheme()

  const appBarStyle = mobileAppBarStyle()
  const openMenuStyle = mobileAppFloatingMenuStyle(theme)
  const mobileLinkStyling = mobileLinkStyle(theme)
  const [isOpen, setOpen] = useState(false)
  const logo = strapiHeader?.logo?.localFile?.childImageSharp?.gatsbyImageData;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.overflow = ""
    }
  }, [isOpen])

  return (
    <>
      <AppBar position="static" elevation={0} sx={{ ...appBarStyle }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ zIndex: 9999 }}>
            <Stack
              sx={{ width: "100%" }}
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
            >
              <GatsbyLink to="/">
                <Box sx={{ display: "none" }}>Home</Box>
                {logo && <GatsbyImage image={logo} alt="logo" loading='eager' />}
              </GatsbyLink>

              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                justifyContent={"flex-end"}
              >
                <div>
                  <IconButton
                    title="Toggle menu"
                    onClick={() => setOpen(!isOpen)}
                  >
                    {isOpen ? (
                      <Close sx={{ color: "white" }} />
                    ) : (
                      <Menu sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </div>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      {isOpen && (
        <Box sx={openMenuStyle}>
          <nav>
            <Stack direction={'column'} sx={{ width: '100%' }} alignItems={'stretch'}>
              {strapiHeader?.links && (
                <LinkList links={strapiHeader?.links as LinkGroupProps[]} alignLeft relativeLinkComponent={GatsbyLink} />
              )}
            </Stack>
          </nav>
        </Box>
      )}
    </>
  )
}
